(function () {
    'use strict';

    angular.module('archie.core').factory('DrmPlayerService', DrmPlayerService);
    function DrmPlayerService() {
        var player = null;

        return {
            setPlayer: setPlayer,
            getPlayer: getPlayer,
            dispose: dispose,
        };

        function setPlayer(instance) {
            player = instance;
        }

        function getPlayer() {
            return player;
        }

        function dispose() {
            if (!player) return;
            if (player.isFullscreen()) {
                // if player is in full screen mode must exit first
                // or get console errors
                player.exitFullScreen();
            }
            player.dispose();
            player = null;
        }
    }

    angular.module('archie.core').controller('drmPlayerController', DrmPlayerController);
    function DrmPlayerController(
        $scope,
        DrmPlayerService,
        PreferenceUtilService,
        OmnitureService,
        UserService,
        ErrorService,
        drm,
        MessageConsole
    ) {
        // for instance of videojs player
        $scope.player = null;

        // initialize videojs on video element
        // add any event handlers
        $scope.initPlayer = function (el) {
            //just in case
            DrmPlayerService.dispose();
            //initialize videojs
            $scope.player = videojs(el, {
                html5: {
                    // Safari fix
                    nativeCaptions: false,
                    setNativeCaptions: [false],
                    dash: {
                        // necessary for sending cookies in header in requests for video files (chrome, firefox)
                        setXHRWithCredentialsForType: ['default', true],
                    },
                    vhs: {
                        nativeCaptions: false,
                        setNativeCaptions: [false],
                        // necessary for sending cookies in header in requests for video files (safari)
                        withCredentials: true,
                    },
                },
                controls: true,
                controlBar: {
                    children: [
                        'progressControl',
                        'playToggle',
                        'playbackRateMenuButton',
                        'volumePanel',
                        'currentTimeDisplay',
                        'timeDivider',
                        'durationDisplay',
                        'controlSpacer',
                        'shareButton',
                        'fullscreenToggle',

                        //   Original list with default control bar:
                        //
                        //   'playToggle',
                        //   'volumePanel',
                        //   'currentTimeDisplay',
                        //   'timeDivider',
                        //   'durationDisplay',
                        //   'progressControl',
                        //   'liveDisplay',
                        //   'seekToLive',
                        //   'remainingTimeDisplay',
                        //   'customControlSpacer',
                        //   'playbackRateMenuButton',
                        //   'chaptersButton',
                        //   'descriptionsButton',
                        //   'subsCapsButton',
                        //   'audioTrackButton',
                        //   'fullscreenToggle'
                    ],
                },
                autoplay: !!$scope.autoplay,
                inactivityTimeout: isFinite($scope.inactivityTimeout)
                    ? $scope.inactivityTimeout
                    : 5000,
                preload: 'auto',
                retryOnError: true,
                sourceOrder: false,
                poster: $scope.poster ? String($scope.poster) : undefined,
                playbackRates: [1, 2, 4],
                plugins: {
                    loopSegment: {
                        start: $scope.start,
                        end: $scope.end,
                    },
                    metadataView: {
                        config: $scope.metadataView,
                    },
                    shareLink: {
                        url: $scope.url,
                    },
                    drmSource: {
                        src: $scope.video,
                        drmSettings: drm,
                        authToken: encodeURIComponent('Bearer ' + UserService.profile.token),
                    },
                    title: {
                        text: $scope.videoTitle,
                    },
                    closeVideo: {
                        closeFn: $scope.closeVideoFn,
                    },
                    timelineMetadata: {
                        segments: $scope.segments,
                    },
                    snowplow: {},
                },
                __metadata__: {
                    // allows to access data via player.options()
                    title: $scope.videoTitle,
                    asset: $scope.asset,
                },
            });

            DrmPlayerService.setPlayer($scope.player);

            handleEvents();
        };

        // register event handlers
        function handleEvents() {
            $scope.player.on('volumechange', function () {
                var videoSettings = {
                    volume: $scope.player.volume(),
                    muted: $scope.player.muted(),
                };
                // saves volume and muted status to localStorage
                PreferenceUtilService.set('videoSettings', JSON.stringify(videoSettings));
            });
            $scope.player.on('ready', function () {
                var videoSettings = JSON.parse(PreferenceUtilService.get('videoSettings'));
                if (!videoSettings) return;
                // retrieve volume and muted status from localStorage
                $scope.player.volume(videoSettings.volume || 1);
                $scope.player.muted(videoSettings.muted || false);
            });
            $scope.player.one('loadedmetadata', function () {
                OmnitureService.videoView($scope.videoTitle + ' - ' + $scope.player.currentSrc());
            });
            $scope.player.on('error', function () {
                $scope.closeVideoFn();
                ErrorService.videoError(
                    $scope.player.error().code,
                    $scope.player.error().message,
                    $scope.videoInfo
                );
            });
            $scope.player.on('copyurlerror', function () {
                MessageConsole.displayMessage({
                    type: 'e',
                    msg: 'please press Ctrl/Cmd+C to copy',
                    delay: 5000,
                });
            });
        }

        $scope.$on('$destroy', function () {
            DrmPlayerService.dispose();
        });
    }

    angular.module('archie.core').directive('drmPlayer', DrmPlayerDirective);
    function DrmPlayerDirective() {
        return {
            restrict: 'E',
            templateUrl: 'videojs/drm-player.html',
            scope: {
                video: '=?', // The video URL. Either this or videoFn or drmFn must be set, but not more than 1.
                videoFn: '&?', // A callback that returns a promise for the video URL. Either this or video or drmFn must be
                // set, but not both.
                videoTitle: '=?', // a Title string that will appear as overlay in upper-right corner
                closeVideoFn: '&?', // function that will remove the video container from DOM
                autoplay: '=',
                inactivityTimeout: '=?',
                poster: '=', // The poster that is shown before play starts.
                start: '=', // The start time of the segment.
                end: '=', // The end time of the segment.
                url: '=', // a url to the open video for deeplinking support.
                segments: '=?',
                reloadInterval: '=', // How often the video URL needs to be reloaded. For use with videoFn.
                isDrm: '=?',
                videoInfo: '=?',
                metadataView: '=?',
                asset: '=',
            },
            replace: false,
            controller: 'drmPlayerController',
            link: function (scope, element) {
                scope.inactivityTimeout = parseInt(scope.inactivityTimeout);
                scope.initPlayer(element.find('video')[0]);
            },
        };
    }
})();
